var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "w-full grid gap-x-6",
      class: [_vm.explanation ? "grid-cols-12" : "grid-cols-6"],
    },
    [
      _c("label", { staticClass: "col-span-2 my-auto text-right text-sm" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _c("ValidationProvider", {
        class: _vm.label ? "col-span-4 relative" : "col-span-6 relative",
        attrs: {
          tag: "div",
          vid: _vm.fieldId,
          rules: _vm.validateRule,
          name: _vm.fieldName,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.sublabel,
                        expression: "sublabel",
                      },
                    ],
                    staticClass: "text-xxs text-left absolute sub-label",
                  },
                  [_vm._v(_vm._s(_vm.sublabel))]
                ),
                _c(
                  "select",
                  {
                    staticClass: "w-full h-10 bg-white border rounded",
                    class: [
                      errors.length
                        ? "bg-red-200 border-red-600 error"
                        : "border-brown-grey",
                      _vm.disabled ? "bg-light-grey" : "",
                    ],
                    attrs: { name: _vm.fieldName, disabled: _vm.disabled },
                    domProps: { value: _vm.inputValue },
                    on: { input: _vm.setInputValue },
                  },
                  [
                    _vm.unselected
                      ? _c("option", { attrs: { selected: "", value: "" } })
                      : _vm._e(),
                    _vm._l(_vm.options, function (option, key) {
                      return _c(
                        "option",
                        {
                          key: key,
                          domProps: {
                            value: option.value,
                            selected: option.value === _vm.value,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.needsTranslation
                                  ? _vm.$t(option.text)
                                  : option.text
                              ) +
                              " "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: errors.length,
                        expression: "errors.length",
                      },
                    ],
                    staticClass: "text-xxs text-left text-red-600 absolute",
                  },
                  [_vm._v(_vm._s(errors[0]))]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.explanation,
              expression: "explanation",
            },
          ],
          staticClass: "col-span-6 py-3 text-xs text-left",
        },
        [_c("span", [_vm._v(_vm._s(_vm.explanation))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }